import React from 'react'
import { FormattedMessage } from 'react-intl'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Carousel from '../components/carousel'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <Carousel
      size="is-fullheight"
      title={<FormattedMessage id="error--title" />}
      subtitle={<FormattedMessage id="error--subtitle" />}
      button={<FormattedMessage id="error--button-1" />}
      bclass="back-button"
    />
  </Layout>
)

export default NotFoundPage
